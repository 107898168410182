import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useEntradaNFE } from '~/pages/EntradaNFE/EntradaNFEContext';

import { ClearButton, ConfirmButton } from '~/components/Buttons';
import {
  CalculoCondicao,
  CalculoResponse,
  InputDate,
  InputMoney,
  InputSelect,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { ButtonItens } from './styles';
import { FaSync } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import clearForm from './clearForm.json';
import { toast } from 'react-toastify';
import { CalcDataCondicao } from '~/pages/DevolucaoESaidaNFDeFornecedor/functions/Procedures';
import api from '~/services/api';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import Swal from 'sweetalert2';
import { deleteItens, insertOrUpdate } from '~/utils/masterDetail';
import { nanoid } from 'nanoid';
import moment from 'moment';
import { Parcela } from '~/pages/EntradaNFE/protocols';
import { compareArrays } from './utils/compareArrays';
import { conditions } from './services';
import { calculateTotalNf } from './utils/calculateTotalNf';

export const FormParcelas: React.FC = () => {
  const [datasNaoInformadas, setDatasNaoInformadas] = useState<boolean>(false);
  const [finalizadoras, setFinalizadoras] = useState<any[]>([]);

  const {
    formParcela,
    formNfe,
    setCurrentTab,
    canUpdate,
    uuidParcela,
    setUuidParcela,
    masterDetail,
    setMasterDetail,
    produtos,
    parcelas,
    setParcelas,
    xmlImportado,
    subTotais,
  } = useEntradaNFE();

  useEffect(() => {
    (async () => {
      const res = await api.get('/finalizadora');
      const { data, success } = res.data;
      if (success) {
        const Finalizadoras = data.map((item: any) => {
          return {
            ...item,
            label: item.des_finalizadora,
            value: item.cod_finalizadora,
          };
        });
        setFinalizadoras(Finalizadoras);
      }
    })();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState,
    watch,
  } = formParcela;

  useEffect(() => {
    if (parcelas.length !== 0) {
      const totalParcelas = parcelas.reduce(
        (acc, parcela: any) => acc + parcela.val_parcela,
        0,
      );
      const resultado = subTotais.valTotNfItem - totalParcelas;
      setTimeout(() => {
        setValue(
          'val_parcela',
          formatCurrencyAsText(resultado > 0 ? resultado : 0),
        );
      }, 10);
    } else {
      setTimeout(() => {
        setValue('val_parcela', formatCurrencyAsText(subTotais.valTotNfItem));
      }, 10);
    }
  }, [parcelas, subTotais.valTotNfItem]);

  const { setValue: setValueFormNfe, getValues: getValuesFormNfe } = formNfe;

  const validation = useCallback(async () => {
    const cod_pessoa = getValuesFormNfe('cod_pessoa');
    const dataEmissao = getValuesFormNfe('dta_emissao');
    const dataEntrada = getValuesFormNfe('dta_entrada');

    if (cod_pessoa === undefined || cod_pessoa === '') {
      toast.warning('Fornecedor deve ser selecionado');
      return true;
    }
    if (dataEmissao === '' || dataEmissao === undefined) {
      toast.warning('Data de emissão deve ser informada.');
      return true;
    }

    if (dataEntrada === '') {
      toast.warning('Data de entrada deve ser informada');
      return true;
    }

    if (new Date(dataEntrada) < new Date(dataEmissao)) {
      toast.warning(
        'Data de Emissão não deve ser maior que Data de Entrada/Saída.',
      );
      return true;
    }
    if (produtos.length === 0) {
      toast.warning('Item(s) da NF deve(m) ser informado');
      return true;
    }
    return false;
  }, [getValuesFormNfe, produtos]);

  const handleRecalculaFinanceiro = useCallback(async () => {
    if (await validation()) return;

    const fornecedor = getValuesFormNfe('cod_pessoa').value;
    const tipo_condicao = getValuesFormNfe('cod_perfil').tipo_operacao;

    const showNoConditionsAlert = async () => {
      await Swal.fire({
        text: 'Nenhuma condição de Pagamento localizada para esse parceiro',
        icon: 'info',
        showConfirmButton: false,
        timer: 1500,
      });
    };

    const createOptions = (data: any[], totalNf: any) => {
      const valParcela = data.length <= 0 ? 0 : Number(totalNf) / data.length;
      const valParcelaAjustado = parseFloat(valParcela.toFixed(2));

      return data.map((item, index) => {
        const uuid = nanoid();
        const { cod_cc, dta_emissao, dta_entrada } = getValuesFormNfe();
        let dta_vencimento = CalcDataCondicao(
          cod_cc,
          dta_emissao,
          dta_entrada,
          item.num_condicao,
          0,
        );

        dta_vencimento = moment(dta_vencimento).format('YYYY-MM-DD');
        const parsedDate = parseISO(dta_vencimento);
        const formattedDate = format(parsedDate, 'yyyy-MM-dd');

        return {
          uuid,
          id: uuid,
          cod_parcela: undefined,
          num_registro: index + 1,
          num_condicao: item.num_condicao,
          cod_condicao: item.cod_condicao,
          des_condicao: item.des_condicao,
          cod_finalizadora: item.cod_finalizadora,
          des_finalizadora: `${item.cod_finalizadora} - ${item.des_finalizadora}`,
          dta_vencimento: formattedDate,
          val_parcela: transformAsCurrency(valParcelaAjustado),
        };
      });
    };

    const updateParcelas = async (options: any) => {
      if (!compareArrays(options, parcelas)) {
        await Promise.all(
          options.map((p: any) =>
            insertOrUpdate('parcelas', p, masterDetail, setMasterDetail),
          ),
        );
      }

      options.sort((a: any, b: any) => a.num_condicao - b.num_condicao);
      setParcelas(options);
    };

    const handleError = (error: any) => {
      const message = error.data?.message || String(error);
      toast.error(message);
    };

    const processConditions = async () => {
      try {
        const { data, success } = await conditions.fetchConditions(
          fornecedor,
          tipo_condicao,
        );

        if (!success) return;
        if (data.length === 0) {
          const {
            cod_perfil: { cod_perfil },
          } = getValuesFormNfe();
          const { data: dataPerfil, success: successPerfil } =
            await conditions.fetchPerfilConditions(cod_perfil);
          if (!successPerfil) return;

          const totalNf = calculateTotalNf(produtos, xmlImportado);
          const options = createOptions(dataPerfil, totalNf);
          if (dataPerfil.length === 0) await showNoConditionsAlert();
          else await updateParcelas(options);
          return;
        }
        const totalNf = calculateTotalNf(produtos, xmlImportado);
        const options = createOptions(data, totalNf);
        await updateParcelas(options);
      } catch (error) {
        handleError(error);
      }
    };

    if (parcelas.length <= 0) {
      await processConditions();
    } else {
      const confirmaRecalculo = await Swal.fire({
        title:
          'Deseja pesquisar novamente as condições de pagamento deste parceiro?',
        text: 'As parcelas atuais poderão sofrer alteração de prazos, formas de pagamento e valores.',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => result.isConfirmed);

      if (confirmaRecalculo) {
        await processConditions();
      }
    }
  }, [
    validation,
    getValuesFormNfe,
    xmlImportado,
    masterDetail,
    setMasterDetail,
    setParcelas,
    produtos,
    parcelas,
  ]);

  const handleVencimento = useCallback(
    (condicaoData: CalculoResponse) => {
      if (condicaoData.error === 'Datas Devem ser informadas.') {
        setDatasNaoInformadas(true);
        return;
      }
      if (condicaoData.error !== 'Datas Devem ser informadas.') {
        setDatasNaoInformadas(false);
      }
      setValue(
        'dta_vencimento',
        format(new Date(condicaoData.vencimento), 'yyyy-MM-dd'),
      );
      setValue('num_condicao', condicaoData.numDias);
      setValue('condicao', condicaoData.condicao);
    },
    [setValue],
  );

  const handleAddParcela = handleSubmit(async () => {
    setValue('condicao_update', false);
    const uuid = uuidParcela?.uuid === undefined ? nanoid() : uuidParcela?.uuid;
    const {
      condicao,
      num_condicao,
      finalizadora,
      dta_vencimento,
      val_parcela,
    } = getValues();

    const data: Parcela = {
      uuid,
      id: uuid,
      cod_parcela: uuidParcela?.cod_parcela,
      num_registro: parcelas.length + 1,
      cod_condicao: condicao.value,
      des_condicao: condicao.label,
      num_condicao,
      cod_finalizadora: finalizadora.value,
      des_finalizadora: `${finalizadora.value} - ${finalizadora.label}`,
      dta_vencimento,
      val_parcela: transformAsCurrency(val_parcela),
    };
    const parcelasData: any[] = await insertOrUpdate(
      'parcelas',
      data,
      masterDetail,
      setMasterDetail,
    );

    const othersParcels = parcelas.filter((p) => p.uuid !== uuid);

    const parcels = [...othersParcels, ...parcelasData];

    parcels.forEach((parcel) => {
      deleteItens('parcelas', parcel.uuid, masterDetail, setMasterDetail);
    });

    parcels.sort((a, b) => a.num_condicao - b.num_condicao);

    const seen = new Set<string>();
    const result: any[] = [];

    parcels.forEach((op) => {
      const key = [
        'cod_condicao',
        'cod_finalizadora',
        'cod_parcela',
        'des_condicao',
        'num_condicao',
      ]
        .map((k) => op[k])
        .join('|');

      if (!seen.has(key)) {
        seen.add(key);
        result.push(op);
      }
    });

    const parcelCorrigida = result.map((p) => {
      const [firstPart, ...restParts] = p.des_finalizadora.split(' - ');
      const lastPart = restParts.length ? restParts[restParts.length - 1] : '';
      const description = restParts.length
        ? `${firstPart} - ${lastPart}`
        : firstPart;

      return { ...p, des_finalizadora: description };
    });

    parcelCorrigida.forEach(async (p) => {
      await insertOrUpdate('parcelas', p, masterDetail, setMasterDetail);
    });

    setParcelas(parcelCorrigida);
    handleClearForm();
  });

  const handleClearForm = () => {
    setValue('condicao_update', false);
    reset(clearForm);
    setUuidParcela(undefined);
  };

  return (
    <>
      <Row>
        <Col md={10} sm={12}>
          <Separator labelText="Financeiro" sidePadding="0 0" />
        </Col>
        <Col md={2} sm={12}>
          <ButtonItens
            disabled={!canUpdate}
            type="button"
            onClick={handleRecalculaFinanceiro}
          >
            <FaSync color="#28a745" />
            <span>(Re) Calcular</span>
          </ButtonItens>
        </Col>
      </Row>
      <Row className="d-flex align-items-center position-relative">
        <Col sm={12} md={3}>
          <InputSelect
            label="Finalizadora"
            maxLength={50}
            placeholder="Selecione..."
            name="finalizadora"
            disabled={!canUpdate}
            register={register}
            isError={!!formState.errors.finalizadora}
            options={finalizadoras}
            control={control}
            changeSelected={(selected: any) => {
              setValue('finalizadora', selected);
            }}
          />
        </Col>
        <CalculoCondicao
          name="condicaoNFSaida"
          register={register}
          getValues={getValues}
          control={control}
          setValue={setValue}
          formState={formState}
          watch={watch}
          fieldToWatch="finalizadora"
          disabled={!canUpdate}
          dataEmissao={getValuesFormNfe('dta_emissao')}
          dataEntrada={getValuesFormNfe('dta_entrada')}
          changeCondicao={(result: any) => {
            handleVencimento(result);
          }}
        />
        <Col sm={12} md={2}>
          <InputDate
            register={register}
            label="Vencimento"
            name="dta_vencimento"
            control={control}
            disabled={!canUpdate}
            isError={!!formState.errors.dta_vencimento}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            name="val_parcela"
            register={register}
            isError={!!formState.errors.val_parcela}
            min={0}
            label="Valor"
            disabled={!canUpdate}
          />
        </Col>
        <Col md={12} sm={12} className="d-flex mt-2 justify-content-end">
          <ConfirmButton onClick={handleAddParcela} disabled={!canUpdate} />
          <ClearButton onClick={handleClearForm} disabled={!canUpdate} />
        </Col>
      </Row>
      <Col sm={12} style={{ marginTop: '15px' }}>
        {datasNaoInformadas === true && (
          <Alert variant="warning">
            Para o cálculo do vencimento é necessário{' '}
            <b
              onClick={() => {
                // setFocus('dta_emissao');
                setCurrentTab('nfe');
              }}
              style={{ cursor: 'pointer' }}
            >
              informar
            </b>{' '}
            as datas de emissão e saída da NF.
          </Alert>
        )}
      </Col>
    </>
  );
};
function formatCurrencyAsTextformat(valTotNfItem: any): any {
  throw new Error('Function not implemented.');
}
